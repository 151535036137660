import Header from "../components/Header.js";
import Massage_CMPNT from "../components/Massage_CMPNT.js";
import Footer from "../components/Footer.js"

export default function Massage() {
    return (
        <>
            <Header/>
            <Massage_CMPNT/>
            <Footer/>
        </>
    )
}