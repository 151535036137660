import Haillicourt from "../assets/haillicourt.png"
import Mericourt from "../assets/mericourt.png"
import Bruaysis from "../assets/rpebruaysis.png"
import Harnes from "../assets/rpeharnes.jpg"
import Rouvray from "../assets/saintetiennedurouvray.png"
import Slb from "../assets/slb.jpg"

export default function CONFIANCE_CMPNT() {
    return(
        <div className="grid">
            <div className="one">
                <h2>Haillcourt</h2>
                <img className="grid__img" src={Haillicourt} alt="Haillcourt"></img>
            </div>
            <div className="two">
                <h2>Mericourt</h2>
                <img className="grid__img" src={Mericourt} alt="Mericourt"></img>
            </div>
            <div className="three">
                <h2>Relais Petite Enfance Bruaysis</h2>
                <img className="grid__img" src={Bruaysis} alt="Bruaysis"></img>
            </div>
            <div className="four">
                <h2> Relais Petite Enfance Harnes</h2>
                <img className="grid__img" src={Harnes} alt="Harnes"></img>
            </div>
            <div className="five">
                <h2>Saint-Etienne du Rouvray</h2>
                <img className="grid__img" src={Rouvray} alt="Rouvray"></img>
            </div>
            <div className="six">
                <h2>Saint-Laurent Blangy</h2>
                <img className="grid__img" src={Slb} alt="Saint-Laurent Blangy"></img>
            </div>
           
        </div>
    )
}