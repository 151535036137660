import Header from "../components/Header.js";
import Rebozo_CMPNT from "../components/Rebozo_CMPNT.js"
import Footer from "../components/Footer.js"

export default function Rebozo() {
    return (
        <>
            <Header/>
            <Rebozo_CMPNT/>
            <Footer/>
        </>
    )
}