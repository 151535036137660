export default function CONTACT_CMPNT() {
    function appointy() {
        const ifrm = document.getElementById("appointy-iframe");
        window.addEventListener("message", function (e) {
            const d = e.data || {};
            if (d.type === "height"){
                ifrm.style.height = d.data + "px";
            }
            if (d.type === "scroll") {
                ifrm.scrollIntoView();
            }
        });
    };
    appointy();
    return(
        <div className="container">
            <iframe id="appointy-iframe" class="no-border" src="https://booking.appointy.com/Bubblemome?isgadget=1&autoheight=1"  scrolling="no" width="100%" frameBorder="0"></iframe>
        </div>
    )
}