import Header from "../components/Header.js"
import CONFIANCE_CMPNT from "../components/CONFIANCE_CMPNT.js"
import Footer from "../components/Footer.js"

export default function Confiance() {
    return(
        <>
            <Header/>
            <CONFIANCE_CMPNT/>
            <Footer/>
        </>
    )
}