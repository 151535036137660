import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Root from './routes/Root.js';
import Portage from './routes/Portage.js'
import Massage from './routes/Massage.js';
import CSAP from './routes/CSAP.js';
import Rebozo from './routes/Rebozo.js';
import DBL from './routes/DBL.js';
import Temoignages from './routes/Temoignages.js';
import Contact from './routes/Contact.js';
import Confiance from './routes/Confiance.js';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

/*const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Root/>
  </React.StrictMode>
);*/

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root/>,
  },
  {
    path: "/portage",
    element: <Portage/>,
  },
  {
    path: "/massage",
    element: <Massage/>,
  },
  {
    path: "/csap",
    element: <CSAP/>,
  },
  {
    path: "/rebozo",
    element: <Rebozo/>,
  },
  {
    path: "/dbl",
    element: <DBL/>,
  },
  {
    path: "/temoignages",
    element: <Temoignages/>,
  },
  {
    path: "/contact",
    element: <Contact/>,
  },
  {
    path: "/confiance",
    element: <Confiance/>,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);