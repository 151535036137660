import Header from "../components/Header.js"
import QSJ from "../components/QSJ.js"
import Footer from "../components/Footer.js"

export default function Root() {
    return (
        <>
            <Header/>
            <QSJ/>
            <Footer/>
        </>
    )
}