import Header from "../components/Header.js";
import CSAP_CMPNT from "../components/CSAP_CMPNT.js";
import Footer from "../components/Footer.js"

export default function CSAP() {
    return (
        <>
            <Header/>
            <CSAP_CMPNT/>
            <Footer/>
        </>
    )
}