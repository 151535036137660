import Header from "../components/Header.js"
import TEMOIGNAGES_CMPNT from "../components/TEMOIGNAGES_CMPNT.js"
import Footer from "../components/Footer.js"

export default function Temoignages() {
    return (
        <>
            <Header/>
            <TEMOIGNAGES_CMPNT/>
            <Footer/>
        </>
    )
}