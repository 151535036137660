import { useState } from "react"
import Hamburger from "../assets/Hamburger_icon.svg"

export default function Menu() {
    const [menu, setMenu] = useState(true)
    const handleOpen = () => {
        setMenu(!menu);
    }
    return (
        menu ? 
        <button id="menu" onClick={handleOpen}>
                    <img src={Hamburger} alt="Menu"></img>
        </button> : (
        <div>
            <button id="menu" onClick={handleOpen}>
            <img src={Hamburger} alt="Menu"></img>
            </button>
            <div>
                <ul className="menu__nav">
                <li><a href="/">Qui suis-je ?</a></li>
                <li><a href="/csap">Communiquation Signée</a></li>
                <li><a href="/dbl">Dunstan Baby Language</a></li>
                <li><a href="/massage">Massage Bébé</a></li>
                <li><a href="/portage">Portage Physiologique</a></li>
                <li><a href="/rebozo">Rebozo</a></li>
                <li><a href="/rebozo">Temoignages</a></li>
                <li><a href="/rebozo">Ils m'ont fait confiance</a></li>
                <li><a href="/rebozo">Contact</a></li>
                </ul>
            </div>
        </div>
        )
    )
}