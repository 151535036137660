import Header from "../components/Header.js"
import Portage_CMPNT from "../components/Portage_CMPNT.js"
import Footer from "../components/Footer.js"

export default function Portage() {
    return (
        <>
            <Header/>
            <Portage_CMPNT/>
            <Footer/>
        </>
    )
}