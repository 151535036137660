import Header from "../components/Header.js"
import CONTACT_CMPNT from "../components/CONTACT_CMPNT.js"
import Footer from "../components/Footer.js"

export default function Contact() {
    return (
        <>
            <Header/>
            <CONTACT_CMPNT/>
            <Footer/>
        </>
    )
}